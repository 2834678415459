import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactBg from "../images/Contact_Background.svg";
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setcompany] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    /*
    document.getElementById("root"). style.backgroundImage =
      "url(" + ContactBg + ")";
    document.getElementById("root").style.backgroundRepeat = "no-repeat";
    document.getElementById("root").style.backgroundSize = "contain";
    return () => {
      document.getElementById("root").style.backgroundImage = null;
    };*/
    document.getElementById("root").classList.add("contactBg");
    return () => {
      document.getElementById("root").classList.remove("contactBg");
      //document.getElementById("root").removeClassName("contactBG");
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      company: company,
      message: message,
    };
    console.log(data);
    axios
      .post("https://analytics.aispeak.ai/contact-form", data)
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="form-section"
      style={{
        width: "42%",
        margin: "auto",
        marginTop: "150px",
        marginBottom: "150px",
      }}
    >
      <h1 style={{ textAlign: "center", fontWeight: "bold" }}>Get In Touch</h1>
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          color: "#818383",
          marginTop: "32px",
          marginBottom: "42px",
        }}
      >
        You got questions? Let us help you.
      </p>
      <form onSubmit={handleSubmit}>
        <label style={{ display: "block", color: "#222323" }}>Name *</label>
        <input
          placeholder="Enter your name"
          className="contact-input"
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label style={{ display: "block", color: "#222323" }}>Email *</label>
        <input
          placeholder="Enter your email here"
          className="contact-input"
          type="email"
          name="name"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label style={{ display: "block", color: "#222323" }}>
          Company Name
        </label>
        <input
          placeholder="Optional"
          className="contact-input"
          type="text"
          name="name"
          value={company}
          onChange={(e) => setcompany(e.target.value)}
        />
        <label style={{ display: "block", color: "#222323" }}>Message</label>
        <textarea
          placeholder="What would you like to know?"
          className="contact-input"
          id="w3review"
          name="w3review"
          rows=""
          cols=""
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <button
          className="contact-btn button-main"
          style={{ display: "block", margin: "auto", marginTop: "40px" }}
          type="submit"
        >
          Send Inquiry
        </button>
      </form>
    </div>
  );
}

export default Contact;
