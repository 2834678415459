import React from "react";
import Contact from "../components/contact";
function Technology() {
  return (
    <div id="contactbg">
      <Contact></Contact>
    </div>
  );
}

export default Technology;
